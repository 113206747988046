




















































































































import CarouselComponent from "@/components/CarouselComponent.vue"
import ProductCategory from "@/models/ProductCategory"
import ProductCategoryService from "@/services/ProductCategoryService"
import ConstantTool from "@/services/tool/ConstantTool"
import LangModule from "@/store/LangModule"
import {Component, Vue, Watch} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import ContactComponent from "@/components/ContactComponent.vue";
import QueryComponent from "@/components/QueryComponent.vue";

@Component({ components: { CarouselComponent, ContactComponent, QueryComponent } })
export default class ContactView extends Vue {
	categories: ProductCategory[] = []
  lang: any = getModule(LangModule).lang
  images = [
		{ src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg', },
		{ src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg', },
		{ src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg', },
		{ src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg', },
	]
	filteredCategories: ProductCategory[] = []

	created() {
		ProductCategoryService.getProductCategories(this, this.categories)
	}

	//Cuando reciba las categorías
	@Watch('categories')
	onCategoriesUpdated() {
		this.filteredCategories = this.findCategoryChildrens(6)
	}

	findCategoryChildrens(productCategoryId: number): ProductCategory[] {
		let list: ProductCategory[] = []

		this.categories.filter(c => (c.parent?.id == productCategoryId)).forEach(category => {
			list.includes(category) ? null : list.push(category)

			this.categories.filter(c => c.parent?.id == category.id).forEach(category2 => {
				list.includes(category2) ? null : list.push(category2)

				this.categories.filter(c => c.parent?.id == category.id).forEach(category3 => {
					list.includes(category3) ? null : list.push(category3)

					this.categories.filter(c => c.parent?.id == category.id).forEach(category4 => {
						list.includes(category4) ? null : list.push(category4)
					})

				})
			})
		})

		return list
	}

	get convert() {
		let categoryIds: string = ""
    for (const category of this.filteredCategories) {
        categoryIds = categoryIds + category.id + ','
    }
    categoryIds = categoryIds.slice(0, categoryIds.length-1)
		return categoryIds
	}
}
